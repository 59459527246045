import React from 'react';

function projects() {
  return (
    <div className='projects' id='projects'>
      <div className='project-images-container'>
        <div className='project-image image-1'></div>
        <div className='project-image image-2'></div>
        <div className='project-image image-3'></div>
        <div className='project-image image-4'></div>
        <div className='project-image image-5'></div>
      </div>
      <h1 className='projects-header'>Our Past Projects:</h1>

      <div className='project-container'>
        <div className='project-column'>
          <h3 className='project-title'>Affirmed Housing</h3>
          <p className='project-text'>Westhaven</p>
          <p className='project-text'>The Dahlia</p>
          <p className='project-text'>Nadeau Street</p>
          <p className='project-text'>Asante</p>
          <p className='project-text'>Emerson</p>
          <p className='project-text'>Lumina</p>
          <p className='project-text'>Aria</p>
          <p className='project-text'>2444 Barry</p>

          <h3 className='project-title'>Merlone Geier</h3>
          <p className='project-text'>NoHo West</p>

          <h3 className='project-title'>Trammell Crow Residential (20-year client)</h3>
          <p className='project-text'>Alexan South Echo</p>
          <p className='project-text'>Alexan NoHo</p>
          <p className='project-text'>9th & Hill</p>

          <h3 className='project-title'>Shea Properties</h3>
          <p className='project-text'>Symmetry</p>

          <h3 className='project-title'>Smart & Final</h3>
          <p className='project-text'>Various Locations</p>

          <h3 className='project-title'>Lumen</h3>
          <p className='project-text'>Conversion of two high rise towers to a single building</p>
        </div>
        <div className='project-column'>
          <h3 className='project-title'>Equity Office</h3>
          <p className='project-text'>9911 Pico</p>
          <p className='project-text'>Howard Hughes</p>

          <h3 className='project-title'>Kaiser (20-year client)</h3>
          <p className='project-text'>Watts Health Pavilion</p>

          <h3 className='project-title'>University of Southern California (25-year client)</h3>
          <p className='project-text'>Michelson</p>

          <h3 className='project-title'>American Campus Community</h3>
          <p className='project-text'>Curry Hall</p>
          <p className='project-text'>Norris Research Tower</p>
          <p className='project-text'>Iovine Young Hall</p>
          <p className='project-text'>Track & Field Administration Building</p>
          <p className='project-text'>Ginsburg Hall</p>
        </div>
      </div>
    </div>
  );
}

export default projects;