import React from 'react';

function Services() {
  return (
    <div className='services' id='services'>
      <h1 className='services-header'>What We Offer:</h1>
      <h3 className='text-blue text-bold services-subheader' id='services-1'>Due Diligence</h3>
      <p className='services-text'>Bruce A Miller &amp; Associates provides extensive records research through City records that assist
        clients in the understanding of the existing zoning constraints development regulations, planning
        requirements, and timelines that can impact potential projects. </p>
      <p className='services-text'>  
        Our thorough research includes: obtaining permit records, planning records, plan maintenance
        files, affidavits, covenant and agreements, commission files, board reports and ZIMAS property
        profile reports. We obtain the necessary requirements that could impact the project from the
        Bureau of Engineer, Department of Transportation and the Fire Department. BAMA assists in
        coordinating case management meetings with LADBS and all necessary agencies to discuss the
        feasibility of the proposed project.
      </p>
      <p className='services-text'>Contact us today to prepare a due diligence report for your project.</p>
      <p className='services-text'>Thank you from the Bruce A Miller &amp; Associates Inc team.</p>

      <h3 className='text-blue text-bold services-subheader' id='services-2'>Plan Review/Code Consulting</h3>
      <p className='services-text'>Bruce A Miller &amp; Associates offers preliminary plan review to identify potential code violations
        that can severely impact a project. Our staff has unique knowledge of how plan check staff think
        since several staff are retired plan check engineers. With our background we can give insight to
        the development team to avoid costly delays in the submission of your plans to various
        governmental agencies for approval.
      </p>
      <p className='services-text'>Our process for preliminary plan check is to schedule a meeting to discuss the project. Submit
        your plans to our team, and we will review them for zoning, building, accessibility and life safety
        code violations. Once our review is completed, we will provide written feedback based on our
        experience and knowledge of the code.</p>
      <p className='services-text'>We assist the project team in the preparation of code modifications and adequate responses to
        plan check corrections from the government agencies.</p>
      <p className='services-text'>During our preliminary plan check process, we will provide records research, identify possible
        code modifications, facilitate case management meeting if necessary and estimated plan check
        and permitting fees.</p>

      <h3 className='text-blue text-bold services-subheader' id='services-3'>Permit Expediting &amp; Plan Check Submittal</h3>
      <p className='services-text'>Submittal: Our team handles the submittal process so
        the design team can keep their focus on their scope. We attend all verification appointments and
        walk the plan check staff through the corrections and responses. BAMA provide a matrix of
        agency approvals and provides constant updates on the status of each review.</p>
      <p className='services-text'>With over 30 years of experience in permit processing and our relationships with city agencies
        the Bruce A Miller &amp; Associates team knows the ins and outs of the plan check process.</p>
      <p className='services-text'>BAMA processes a variety of permits such as:</p>
      <p className='services-text text-bold'>Building Permits that include:</p>
      <ul>
        <li>- Demolition</li>
        <li>- Grading</li>
        <li>- Shoring</li>
        <li>- New Construction:</li>
        <ul>
          <li>- Residential</li>
          <li>- Mixed Use</li>
          <li>- Commercial</li>
        </ul>
        <li>- Tenant Improvement</li>
        <li>- Retaining Walls</li>
        <li>- Mechanical</li>
        <li>- Electrical</li>
        <li>- Plumbing</li>
        <li>- Plumbing Site Utilities</li>
        <li>- Deferred submittals</li>
        <li>- Signage</li>
        <li>- Modifications to deviate from specific code requirements</li>
      </ul>
      <p className='services-text text-bold'>Following is a list of agency approvals that may be required in order to obtain your permit:</p>
      <ul>
        <li>- Bureau of Engineering</li>
        <li>- Department of City Planning</li>
        <li>- Bureau of Sanitation</li>
        <li>- Bureau of Street Services</li>
        <li>- Fire Department Title 19</li>
        <li>- Fire Department Hydrants &amp; Access</li>
        <li>- Green Building</li>
        <li>- Disabled Access</li>
        <li>- Department of Transportation</li>
        <li>- County of Los Angeles Health Department</li>
        <li>- County of Los Angeles Industrial Waste</li>
        <li>- HCID LA</li>
        <li>- Metropolitan Transit Authority approvals</li>
        <li>- CalOSHA</li>
      </ul>
      <p className='services-text text-bold'>Following is a list of agency approvals that may be required during construction:</p>
      <ul>
        <li>- Urban Forestry Tree Removal</li>
        <li>- Traffic lane &amp; sidewalk closures</li>
        <li>- Soils &amp; Geology Report approvals</li>
        <li>- Haul Routes</li>
        <li>- Traffic Lane Closures</li>
        <li>- Request for Modifications</li>
        <li>- LADWP Temporary and Permanent Power</li>
        <li>- Noise Variances</li>
        <li>- Signage permits</li>
      </ul>

      <h3 className='text-blue text-bold services-subheader' id='services-4'>Inspection Coordination &amp; Resolution</h3>
      <p className='services-text'>Bruce A Miller &amp; Associates works with ownership, Contractor’s and the design team on our
        projects through to Certificate of Occupancy. With our in-depth knowledge of inspection
        requirements and staff we assist with inspection dispute resolution.</p>
      <p className='services-text'>With our founder being a former City of Los Angeles inspector and our teams extensive
        experience working closely with inspection staff we can assist the team to successfully navigate
        the various inspection process. We assist with all disciplines within the City of Los Angeles
        Department of Building &amp; Safety, City of Los Angeles Fire Department, Contract
        Administration, Urban Forestry, County of Los Angeles Building and Fire. BAMA has
        facilitated temporary certificates of occupancy, phased temporary certificates of occupancy and
        final certificates of occupancy for many complex projects.</p>
      <p className='services-text'>Let BAMA keep your project on track from design concept to occupancy.</p>

      <div className='services-helpful-links'>
        <h3 className='text-blue text-bold services-subheader' id='services-5'>Helpful Links</h3>
        <div className='helpful-links-container'>
          <div className='helpful-links-col'>
            <a href="https://www.ladbs.org/default" target='_blank' rel='noreferrer' className='services-helpful-link'>- LADBS</a>
            <a href="https://inspect.lafd.org/ComCon/tab/rendertab?tabName=Login" target='_blank' rel='noreferrer' className='services-helpful-link'>- Fire Department</a>
          </div>
          <div className='helpful-links-col'>
            <a href="https://planning.lacity.org/" target='_blank' rel='noreferrer' className='services-helpful-link'>- City Planning</a>
            <a href="https://eng.lacity.org/" target='_blank' rel='noreferrer' className='services-helpful-link'>- Bureau of Engineering</a>
          </div>
          <div className='helpful-links-col'>
            <a href="https://ladot.lacity.org/" target='_blank' rel='noreferrer' className='services-helpful-link'>- Department of Transportation</a>
            <a href="https://www.lacitysan.org/san/faces/home" target='_blank' rel='noreferrer' className='services-helpful-link'>- Sanitation</a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Services;