import './App.css';
import Nav from './Components/nav';
import Banner from './Components/banner';
import Services from './Components/services';
import Projects from './Components/projects';
import AboutUs from './Components/aboutus';
import Contact from './Components/contact';
import Footer from './Components/footer';

function App() {
  return (
    <div className="App">
      <Nav />
      <Banner />
      <Services />
      <Projects />
      <AboutUs />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
