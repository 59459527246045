import React from 'react';
import bamaResume from '../Media/BAMA-Resume.pdf';
import bamaLogo from '../Media/bamalogo.svg';

function AboutUs() {
  return(
    <div className='about-us' id='about-us'>
      <div className='about-us-content'>
        <h1 className='about-us-header'>Who We Are:</h1>
        <div className='about-us-container'>
          <div className='about-us-text-container'>
            <p className='about-us-text'>
              Since October of 1986 Bruce A. Miller & Associates Inc has assisted clients in navigating the bureaucratic mazes to obtain development permits. We specialize in the plan check/permitting process, and inspection dispute resolution. As this process can be daunting for even the most seasoned developer BAMA Inc. assists each project from the entitlement phase through to Certificate of Occupancy.
            </p>
            <p className='about-us-text'>
              BAMA Inc has maintained an exceptional understanding of the codes and permitting processes related to development. With several of our staff being ex or retired City personal we have a keen understanding of the interworking of the permitting process.
            </p>
            <p className='about-us-text'>
              We pride ourselves on our integrity and dedication to getting the job done.
            </p>

            <a href={bamaResume} target='_blank' rel='noreferrer' className='about-us-resume-link'>- View our company resume</a>
          </div>
          <img src={bamaLogo} className='about-bama-logo' alt='bama logo'></img>
        </div>
      </div>
    </div>
  );
}

export default AboutUs;