import React, { useState } from 'react';
import { AiOutlineDown } from 'react-icons/ai';

function Nav() {
  const [menuOpen, setMenuOpen] = useState(false);
  const [subMenuOpen, setSubMenuOpen] = useState(false);
  
  /*
  useEffect(() => {
    if(!subMenuOpen) console.log('Submenu closed');
  }, [subMenuOpen]);

  useEffect(() => {
    if(!menuOpen) console.log('Menu closed');
  }, [menuOpen]);
  */

  return (
    <div className='nav' id="nav" onMouseLeave={() => setSubMenuOpen(false)}>
      <div className='nav-content'>
        <div className='nav-logo-container'>
          <h1 className='nav-logo'>
              BRUCE A. MILLER & ASSOCIATES INC.
          </h1>
          <button className={`${menuOpen ? 'rotate-180' : ''} nav-down-arrow`} onClick={() => setMenuOpen(menuOpen => !menuOpen)}>
            <AiOutlineDown />
          </button>
        </div>
        <ul className='nav-list'>
          <li className='nav-list-item'>
            <a href='#' className='nav-link'>Home</a>
          </li>
          <li className='nav-list-item' onMouseOver={() => setSubMenuOpen(true)}>
            <a href='#services' className='nav-link'>Services</a>
            <ul 
              className={`${subMenuOpen ? 'dropdown-active' : ''} nav-services-dropdown`}>
              <li className='nav-services-dropdown-item'>
                <a href='#services-1' className='services-dropdown-link'>Due Diligence</a>
              </li>
              <li className='nav-services-dropdown-item'>
                <a href='#services-2' className='services-dropdown-link'>Plan Review/Code Consulting</a>
              </li>
              <li className='nav-services-dropdown-item'>
                <a href='#services-3' className='services-dropdown-link'>Permit Expediting & Plan Check Submittal</a>
              </li>
              <li className='nav-services-dropdown-item'>
                <a href='#services-4' className='services-dropdown-link'>Inspection Coordination & Resolution</a>
              </li>
              <li className='nav-services-dropdown-item'>
                <a href='#services-5' className='services-dropdown-link'>Helpful Links</a>
              </li>
            </ul>
          </li>
          <li className='nav-list-item'>
            <a href='#projects' className='nav-link'>Projects</a>
          </li>
          <li className='nav-list-item'>
            <a href='#about-us' className='nav-link'>About Us</a>
          </li>
          <li className='nav-list-item'>
            <a href='#contact' className='nav-link'>Contact</a>
          </li>
        </ul>
      </div>
      <div className={`mobile-nav-container ${menuOpen ? '' : 'display-none'}`}>
          <ul className='mobile-nav-list'>
            <li className='mobile-nav-list-item'>
              <a href='#' className='mobile-nav-link' onClick={() => setMenuOpen(false)}>Home</a>
            </li>
            <li className='mobile-nav-list-item'>
              <a href='#services' className='mobile-nav-link' onClick={() => setMenuOpen(false)}>Services</a>
            </li>
            <li className='mobile-nav-list-item'>
              <a href='#projects' className='mobile-nav-link' onClick={() => setMenuOpen(false)}>Projects</a>
            </li>
            <li className='mobile-nav-list-item'>
              <a href='#about-us' className='mobile-nav-link' onClick={() => setMenuOpen(false)}>About Us</a>
            </li>
            <li className='mobile-nav-list-item'>
              <a href='#contact' className='mobile-nav-link' onClick={() => setMenuOpen(false)}>Contact</a>
            </li>
          </ul>
      </div>
    </div>
  );
}

export default Nav;