import React from 'react';

function Banner() {
  return (
    <div className='banner' id='banner'>
      <div className='banner-background'></div>
      <div className='banner-content'>
        {/* <h1 className='banner-header'>Bruce A. Miller & Associates</h1> */}
        <h2 className='banner-text-lg'>Assisting developers with land use consulting since 1986</h2>
        <h2 className='banner-text-sm'>
          With over 150 years of experience, our team of experts will keep your project on track from entitlement to Certificate of Occupancy. 
        </h2>
        <a href='#contact' className='banner-btn'>
          Start Your Next Project &#10230;
        </a>
      </div>
    </div>
  );
}

export default Banner;