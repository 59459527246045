import React from 'react';

function Footer() {
  return (
    <div className='footer'>
      <div className='footer-content'>
        <ul className='footer-list'>
          <li className='footer-list-item'>&#169; {new Date().getFullYear()} Bruce A. Miller & Associates</li>
        </ul>
      </div>
    </div>
  );
}

export default Footer;