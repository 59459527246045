import React, { useState, useEffect } from 'react';

function Contact() {
  const  [name, setName] = useState('');
  const [topic, setTopic] = useState('New Project Inquiry');

  /*
  useEffect(() => {
    console.log(`${name} - ${topic}`);
  }, [name, topic])

  */

  return (
    <div className='contact' id='contact'>
      <div className='contact-text-container'>
        <h1 className='contact-header'>Contact Us</h1>
        <h3 className='contact-text-lg'><p className='text-blue'>Mailing Address:</p> 533 S Fremont Ave. Suite 803 Los Angeles, CA 90071</h3>
        <h3 className='contact-text-lg'><p className='text-blue'>Email:</p> info@bamainc.com</h3>
        <h3 className='contact-text-lg'><p className='text-blue'>Telephone:</p> 213-625-2592</h3>
        <h3 className='contact-text-lg'><p className='text-blue'>Fax:</p> 213-625-0635</h3>
      </div>
      <form name='contact' method='POST' data-netlify='true' className='contact-form'>
        <input type="hidden" name="form-name" value="contact"/>
        <input type='text' name='name' placeholder='Your name (Required)' className='contact-form-name' required onChange={e => setName(e.target.value)}/>
        <input type='email' name='email' placeholder='Your email (Required)' className='contact-form-email' required/>
        <input type='text' name='company-name' placeholder='Company name' className='contact-form-company-name'/>
        <input type='tel' name='phone' placeholder='Phone number' className='contact-form-phone'/>
        <input type="hidden" name="subject" value={`${topic} - ${name}`}/>
        <select id="subject" className='contact-form-subject' name="topic" onChange={e => setTopic(e.target.value)}>
          <option value="General Question">New Project Inquiry</option>
          <option value="Project Status">Project Status</option>
          <option value="Code Question">Code Question</option>
          <option value="General Information">General Information</option>
          <option value="Invoice Question">Invoice Question</option>
        </select>
        <textarea name='message' placeholder='Questions/Comments (Required)' className='contact-form-message' required></textarea>
        <button type='submit' className='contact-form-submit'>Submit</button>
      </form>
    </div>
  );
}

export default Contact;